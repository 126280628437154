import React, { useContext } from "react";

import _ from "app/lang";
import { Cloud, User } from "app/models";
import { pageContext, PartialContext } from "app/page/ContextProvider";

import DrawerMenu, { DrawerMenuItem } from "./DrawerMenu";

export function getMenuItems(context: PartialContext) {
    if (!context.cloud) {
        return [];
    }

    const cloud = new Cloud(context.cloud);
    const user = new User(context);
    const baseUrl = "/cloud/:cloudId";

    const items: DrawerMenuItem[] = [
        {
            icon: "dashboard",
            text: _("Dashboard"),
            href: `${baseUrl}/dashboard`
        },
        {
            icon: "ignite_ap",
            text: _("Devices"),
            href: "/v2/cloud/:cloudId/devices"
        },
        {
            icon: "list_alt",
            text: _("Activity"),
            href: `${baseUrl}/activity`
        }
    ];

    const managementItems: DrawerMenuItem[] = [];

    if (user.hasPermission("manage_sites")) {
        managementItems.push({
            icon: "business",
            text: _("Site management"),
            href: "/v2/cloud/:cloudId/sites"
        });
    } else {
        items.push({
            icon: "business",
            text: _("Sites"),
            href: "/v2/cloud/:cloudId/sites"
        });
    }

    if (user.hasPermission("manage_users")) {
        managementItems.push({
            icon: "users",
            text: _("User management"),
            href: "/v2/cloud/:cloudId/users"
        });
    }

    if (user.hasPermission("manage_licenses")) {
        managementItems.push(
            {
                icon: "money",
                text: _("Licenses & Billing"),
                href: `${baseUrl}/licenses`
            }
        );
    }

    if (user.hasPermission("manage_vpc") && cloud.planType === "vpc") {
        managementItems.push(
            {
                icon: "cloud",
                text: _("VPC"),
                href: `${baseUrl}/vpc`
            }
        );
    }

    // Not sure this is the right permission here? Copied over from PHP code.
    if (user.hasPermission("manage_licenses")) {
        managementItems.push(
            {
                icon: "edit_attributes",
                text: _(" Properties"),
                href: "/v2/cloud/:cloudId/edit"
            }
        );
    }

    if (user.hasPermission("manage_sites")) {
        managementItems.push(
            {
                icon: "notifications",
                text: _("Notifications"),
                href: "/v2/cloud/:cloudId/notification-settings"
            }
        );
    }

    if (managementItems.length > 0) {
        items.push({ text: _("Manage") });
        items.push.apply(items, managementItems);
    }

    return items;
}

export default function CloudMenu() {
    const items = getMenuItems(useContext(pageContext));

    return <DrawerMenu items={items} />;
}
