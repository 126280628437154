import _ from "app/lang";
import { DeviceHealth } from "app/models/Device";
import { Model } from "app/models/Model";
import { AlertType } from "shared/types";

export interface AlertAttributes {
    id: string;
    device_name: string;
    device_id: string;
    site_name: string;
    site_id: string;
    alert_type: AlertType;
    alert_data_1?: string;
    alert_data_2?: string;
    alert_data_3?: string;
    alert_data_json?: any;
    health_id: DeviceHealth;
    is_viewed?: boolean;
    problem_at?: string;
}

type MessageFormatter = (vars: {}) => string;

const messages: Record<AlertType, string | MessageFormatter> = {
    DEV_CONFIG_FAILED: _("Device configuration failed."),
    DEV_UNREACHABLE: _("Unable to reach device."),
    DEV_REBOOT: _("Device rebooted."),
    BOOTBANK_REVERT: _("Bootbank reverted."),
    POWER_CONSUMPTION: _("Power consumption exceeded limit."),
    DEV_REQ_ACTION: _("Device has registration issues that require your attention."),
    MEDIA_SYNC_FAILURE: _("There was a problem when syncing the media to your device."),
    TIME_UNSYNCED: _("Time on device not in sync with the cloud."),
    LINK_60G_DOWN: _("MetroLinq 60 GHz link is down, 5 GHz failover activated (if enabled)."),
    STREAM_FAILED: _("Streaming failure."),
    TASK_FAILED: _("Maintenance task failure."),
    UCI_FILE_SYNC: _("File sync error."),
    CHANNEL_CHANGED: _("Channel changed on radio."),
    FW_UPGRADED: vars => _("Device firmware was upgraded from :data_1 to :data_2", vars),
    FW_DOWNGRADED: vars => _("Device firmware was downgraded from :data_1 to :data_2", vars)
};

export class RecentAlert extends Model<AlertAttributes> {
    get message() {
        let message = messages[this.attrs.alert_type];

        if (typeof message === "function") {
            message = message({
                data_1: this.attrs.alert_data_1,
                data_2: this.attrs.alert_data_2,
                data_3: this.attrs.alert_data_3,
            });
        }

        return message;
    }
}
