import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { siteRoute } from "app/route";
import { drawerMenuHeaderHeight, drawerWidth } from "app/theme";
import SiteSelector, { SiteInfo } from "shared/SiteSelector";

export const siteSelectorHeight = 42;

const styles = (theme: Theme) => createStyles({
    button: {
        border: "none",
        appearance: "none",
        background: "transparent",
        textAlign: "left",
        color: "inherit",
        display: "flex",
        padding: "0 12px 0 16px",
        lineHeight: siteSelectorHeight + "px",
        cursor: "pointer",
        textTransform: "none",
        borderRadius: 0,
        "&:disabled": {
            color: "inherit"
        },
        "&:focus": {
            outline: "none",
        },
        position: "absolute",
        top: drawerMenuHeaderHeight,
        left: 0,
        width: drawerWidth,
        height: siteSelectorHeight,
        borderBottom: "1px solid #e4e4e4"
    }
});
const useStyles = makeStyles(styles);

interface Props {
    selected: SiteInfo | null;
}

export default function DrawerSiteSelector(props: Props) {
    const classes = useStyles();

    function onChange(site: SiteInfo) {
        const siteId = site && site.id;
        const currentSiteId = props.selected && props.selected.id;

        if (!currentSiteId || siteId !== currentSiteId) {
            location.href = siteRoute(site.id);
        }
    }

    return (
        <SiteSelector
            classes={classes}
            selected={props.selected}
            onChange={onChange}
            withCreatingSites={true}
            includeSiteId={props.selected?.id}
        />
    );
}
