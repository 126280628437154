import Cloud from "./Cloud";

export type AutoReRegAction = "allow-create" | "allow-discard";

export interface SiteAttributes {
    allow_config: boolean;
    auto_fw_update?: boolean;
    auto_rereg: AutoReRegAction | null;
    config_saved_at: string;
    cloud_id: string;
    created_at: string;
    default_language: string;
    description: string;
    emails: string;
    id: string;
    lat: number;
    location: string;
    lon: number;
    name: string;
    timezone: string;
    updated_at: string;
    large_site_support: boolean;
    resync_config: boolean;
    config_timeout: number;
    switch_ap_vlan_sync: boolean;
    switch_port_security: boolean;
}

export class Site {
    readonly id: string;
    readonly name: string;
    readonly cloudId: string;
    readonly allowConfig: boolean;
    readonly configSavedAt?: Date;

    private _cloud?: Cloud;

    constructor(
        attrs: SiteAttributes,
        cloud?: Cloud
    ) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.cloudId = attrs.cloud_id;
        this.allowConfig = attrs.allow_config;

        if (attrs.config_saved_at !== null) {
            this.configSavedAt = new Date(attrs.config_saved_at + "Z");
        }

        this._cloud = cloud;
    }

    get cloud() {
        if (this._cloud === undefined) {
            throw new Error("Site#cloud hasn't been initialized");
        }

        return this._cloud;
    }

    get configEnabled() {
        return this.allowConfig;
    }

    hasConfig() {
        return this.allowConfig && this.configSavedAt !== undefined;
    }
}

export default Site;
