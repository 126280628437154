export function getEmbeddedJson(variableName: string) {
    const element = document.querySelector(`[data-variable-name="${variableName}"]`);

    if (element === null) {
        return null;
    }

    return JSON.parse(element.textContent || "{}");
}

export function reloadPage() {
    window.onbeforeunload = null;
    window.location.reload();
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function importExternalScript(url: string): Promise<void> {
    return new Promise(resolve => {
        const imported = document.createElement("script");

        imported.src = url;
        imported.async = true;
        document.head.appendChild(imported);

        imported.addEventListener("load", function () {
            resolve();
        }, {once: true});
    });
}

export function createEvent(type: string) {
    if (typeof(Event) === "function") {
        return new Event(type);
    } else {
        // IE specific code
        const event = document.createEvent("Event");

        event.initEvent(type, true, true);

        return event;
    }
}

export function versionCompare(version1: string|number[], version2: string|number[]): number {
    if (typeof version1 === "string") {
        version1 = parseFwVersion(version1);
    }

    if (typeof version2 === "string") {
        version2 = parseFwVersion(version2);
    }

    // major, minor, patch, revision
    // revision is not evaluated
    for (let i = 0; i < 3; i++) {
        // default to 0, if not set
        const v1 = version1 && version1[i] ? version1[i] : 0;
        const v2 = version2 && version2[i] ? version2[i] : 0;

        if (v1 < v2) {
            return -1;
        } else if (v1 > v2) {
            return 1;
        }
    }

    // versions are equal
    return 0;
}

export function parseFwVersion(fw: string): number[] {
    // possible fw formats:
    // 1.1.9-9791
    // 1.0.4-r6897
    // 1.0.3.4
    // 1.1.4-13125M
    const matches = fw.match(/(\d+)/g);
    const parsed = [];
    const defaultfw = [0, 0, 0, 0];

    if (!matches || matches.length < 3) {
        // throw new Error("Could not parse FW version: " + fw);
        // If we cannot parse FW version, set it to 0.0.0.0 #25437
        return defaultfw;
    }

    for (let i = 0; i < 4; i++) {
        // default to 0 if undefined
        const piece = parseInt(matches[i], 10) || 0;

        parsed.push(piece);
    }

    return parsed;
}