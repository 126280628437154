import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "app/Icon";
import Loading from "app/Loading";
import _ from "app/lang";

import ActionBody from "../ActionBody";
import ActionFooter from "../ActionFooter";

import AlertListItem from "./AlertListItem";
import { RecentAlert } from "./models";

interface Props {
    alerts: RecentAlert[] | null;
    showSites?: boolean;
    onLinkClick?: (href: string) => void;
    onSeeMoreClick?: () => void;
    onAlertClick?: (alertId: string) => void;
    trackingId: string;
}

function AlertList(props: Props) {
    const { alerts, showSites, onLinkClick, onSeeMoreClick, onAlertClick } = props;

    if (alerts === null) {
        return <AlertListLoading />;
    }

    if (alerts.length === 0) {
        return <AlertListEmpty />;
    }

    return (
        <>
            <ActionBody>
                <List disablePadding>
                    {alerts.map((alert, index) => (
                        <AlertListItem key={alert.get("id")}
                            alert={alert}
                            showSite={showSites === true}
                            divider={index > 0}
                            onAlertClick={onAlertClick}
                            onLinkClick={onLinkClick}
                            trackingId={`${props.trackingId}-${alert.attrs.alert_type}`}
                        />
                    ))}
                </List>
            </ActionBody>
            {onSeeMoreClick && (
                <ActionFooter buttons alignRight>
                    <Button size="small"
                        color="primary"
                        onClick={onSeeMoreClick}
                        data-tracking-id={`${props.trackingId}-seeMore`}
                    >
                        {_("See more on Activity page")}
                    </Button>
                </ActionFooter>
              )}
        </>
    );
}

function AlertListLoading() {
    return (
        <List>
            <ListItem dense>
                <Loading center size={40} />
            </ListItem>
        </List>
    );
}

function AlertListEmpty() {
    return (
        <List>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <Icon id="beach_access" />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={_("No recent alerts")}
                    secondary={_("(during last 7 days)")}
                />
            </ListItem>
        </List>
    );
}

export default AlertList;
