import _ from "app/lang";

export type CloudPlanType = "trial" | "intro" | "vpc";
export type CloudSuspendType = "CLOUD_SUSPENDED" | "ADDONS_SUSPENDED";

export const cloudPlanTypeTextual: { [key in CloudPlanType]: string } = {
    trial: _("Trial Plan"),
    intro: _("Core Cloud Plan"),
    vpc: _("Virtual Private Cloud Plan")
};

export interface CloudAttributes {
    id: string;
    name: string;
    plan_type: CloudPlanType;
    badge_of_courage: boolean;
    suspended_at: string | null;
    suspend_type: CloudSuspendType | null;
    large_site_support_allowed: boolean;
    authport_enabled: boolean;
}

export const enum CloudPlan {
    Trial = "trial",
    Core = "intro",
    VPC = "vpc"
}

export class Cloud {
    readonly id: string;
    readonly name: string;
    readonly planType: CloudPlanType;
    readonly badgeOfCourage: boolean;
    readonly largeSiteSupportAllowed: boolean;
    readonly suspendedAt?: Date;
    readonly suspendType?: CloudSuspendType;

    constructor(attrs: CloudAttributes) {
        this.id = attrs.id;
        this.name = attrs.name;
        this.planType = attrs.plan_type;
        this.badgeOfCourage = attrs.badge_of_courage;
        this.largeSiteSupportAllowed = attrs.large_site_support_allowed;

        if (attrs.suspended_at !== null) {
            this.suspendedAt = new Date(attrs.suspended_at + "Z");
        }

        if (attrs.suspend_type !== null) {
            this.suspendType = attrs.suspend_type;
        }
    }

    isSuspended() {
        return this.suspendedAt !== undefined;
    }

    isCourageous() {
        return this.badgeOfCourage;
    }
}

export default Cloud;
