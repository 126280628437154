import { ExtendableError } from "app/error";
import { getUrlParams, staticContext } from "app/page/ContextProvider";
import { insertRouteParams } from "app/page/Link";
import { showAppUpdatedNotification } from "app/private/errorHandling";

// This value has to be slightly greater than backend
// "ignitenet-load-balancers-https" timeout
// in that case server side timeout will trigger first
// otherwise, if end user device can't reach the internet
// the FETCH_REQUEST_TIMEOUT will be triggered
const FETCH_REQUEST_TIMEOUT = 35000;

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export class FetchJSONError extends ExtendableError {
}

export class NetworkError extends FetchJSONError {
    constructor(message: string, name: string) {
        super(message);
        this.name = name;
    }
}

export class ParseJSONError extends FetchJSONError {
}

interface HttpErrorParams {
    statusCode: number;
    url: string;
    responseJSON?: any;
}

// Declaration Merging
export interface HttpError extends HttpErrorParams { }

// eslint-disable-next-line no-redeclare
export class HttpError extends FetchJSONError {
    constructor(message: string, params: HttpErrorParams) {
        super(message);

        Object.assign(this, params);
    }

    toString() {
        return this.url + " has returned " + this.statusCode + " " + this.message;
    }
}

window.fe2 = window.fe2 || {};
window.fe2.HttpError = HttpError;

export interface APIv2ValidationError {
    readonly errorCode: string;
    readonly location: "body" | "headers" | "path" | "query";
    readonly path: string;
    readonly message: string;
}

function isAPIv2ValidationError(value: any): value is APIv2ValidationError {
    return value !== null
        && typeof value.errorCode === "string"
        && typeof value.location === "string"
        && typeof value.path === "string";
}

function formatValidationErrorMessage(url: string, errors: APIv2ValidationError[]) {
    let message = url + ": request validation failed";

    if (errors instanceof Array) {
        for (const error of errors) {
            if (isAPIv2ValidationError(error)) {
                const errorCode = error.errorCode.replace(".openapi.validation", "");

                message += `\n\t${error.path} in ${error.location}: ${errorCode}`;
            }
        }
    }

    return message;
}

export class ValidationError extends HttpError {
    public readonly errors: APIv2ValidationError[];
    public readonly appVersion: string | null;

    constructor(url: string, errors: APIv2ValidationError[], serverAppVersion: string | null) {
        super(formatValidationErrorMessage(url, errors), {
            statusCode: 400,
            url
        });

        this.errors = errors;
        this.appVersion = serverAppVersion;
    }
}

type RequestHeaders = { [name: string]: string };

export interface RequestOptions {
    url: string;
    method?: HttpMethod;
    data?: any;
    timeout?: number;
}

export default function request<T = any>(opts: RequestOptions | string): Promise<T> {
    if (typeof opts === "string") {
        opts = { url: opts };
    }

    let { url, data } = opts;

    url = insertRouteParams(url);

    const { method = "GET", timeout = FETCH_REQUEST_TIMEOUT } = opts;

    if (url.startsWith("http")) {
        // It's a programming error and not a runtime error, so throw an exception
        // instead of returning a rejected promise
        throw new Error("request(): do not use for external requests");
    }

    if (url.indexOf("?") !== -1) {
        // It's a programming error and not a runtime error, so throw an exception
        // instead of returning a rejected promise
        throw new Error("Please do not include URL params in URL, use data argument instead");
    }

    const pageParams = getUrlParams();

    const headers: RequestHeaders = {
        "Accept": "application/json,text/plain;q=0.1",
        "X-CSRFToken": staticContext.csrfToken,
        "X-ClientAppVersion": staticContext.appVersion,
    };

    if (pageParams.cloudId) {
        headers["X-CloudId"] = pageParams.cloudId;
    }

    if (["POST", "PUT", "PATCH", "DELETE"].includes(method)) {
        headers["Content-Type"] = "application/json";
    }

    const options: RequestInit = {
        method,
        headers,
        credentials: "same-origin"
    };

    let fetchTimeout: number;

    if ("AbortController" in window) {
        const controller = new AbortController();

        options.signal = controller.signal;

        fetchTimeout = (window as Window).setTimeout(
            () => controller.abort(),
            timeout
        );
    }

    if (method === "GET") {
        if (!data) {
            data = {};
        }

        // This will force CDN to vary based on application version.
        // Will prevent returning old data format to new app version
        // for cached GET requests.
        data = Object.assign({ _v: staticContext.appVersion }, data);

        const urlParams = Object.keys(data)
            .filter(key => data[key] !== undefined)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            );

        url += "?" + urlParams.join("&");
    } else if (data) {
        options.body = JSON.stringify(data);
    }

    let response: Response;
    let contentType: string;

    return fetch(url, options).then(_response => {
        response = _response;
        contentType = response.headers.get("Content-Type") || "";

        if (contentType.startsWith("application/json")) {
            return response.json();
        } else {
            return response.text();
        }
    }).then(content => {
        const serverAppVersion = response.headers.get("X-ServerAppVersion");

        if (response.status < 400) {
            if (serverAppVersion !== null && staticContext.appVersion !== serverAppVersion) {
                showAppUpdatedNotification("warning");
            }

            return content;
        }

        if (response.status === 400
            && Array.isArray(content)
            && content.some(isAPIv2ValidationError)
        ) {
            throw new ValidationError(url, content, serverAppVersion);
        }

        let message = response.statusText;

        if (typeof content === "string") {
            message = content;
        } else if (content !== null) {
            // When the response is JSON, try and look up a property that could
            // contain the error message
            if (typeof content.message === "string") {
                message = content.message;
            } else if (typeof content.error === "string") {
                message = content.error;
            }
        }

        const responseJSON = (contentType === "application/json")
            ? content
            : undefined;

        throw new HttpError(message, {
            url,
            statusCode: response.status,
            responseJSON
        });
    }).catch(error => {
        if (error instanceof SyntaxError) {
            throw new ParseJSONError(error.message);
        } else if (error instanceof HttpError) {
            throw error;
        }

        throw new NetworkError(error.message, error.name || "NetworkError");
    }).finally(() => {
        if (fetchTimeout) {
            window.clearTimeout(fetchTimeout);
        }
    });
}

export async function uploadFile(url: string, file: File, name = "file") {
    const headers: RequestHeaders = {
        "Accept": "application/json,text/plain;q=0.1",
        "X-CSRFToken": staticContext.csrfToken,
        "X-ClientAppVersion": staticContext.appVersion,
    };

    const pageParams = getUrlParams();

    if (pageParams.cloudId) {
        headers["X-CloudId"] = pageParams.cloudId;
    }

    const formData: FormData = new FormData();

    formData.append(name, file);

    const res = await fetch(url, {
        headers,
        method: "PUT",
        body: formData,
    });

    if (res.status >= 400) {
        throw new HttpError(res.statusText, {
            url,
            statusCode: res.status
        });
    }

    return res.json();
}
