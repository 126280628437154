import React, { useContext } from "react";

import _ from "app/lang";
import { Site, User } from "app/models";
import { Device, DeviceProductType } from "app/models/Device";
import { pageContext, PartialContext } from "app/page/ContextProvider";

import DrawerMenu, { DrawerMenuItem } from "../DrawerMenu";

import getGlinqItems from "./glinq";
import getMeshlinqItems from "./meshlinq";
import getMetrolinqItems from "./metrolinq";
import getOpenLanItems from "./openlan";
import getOpenWifiItems from "./openwifi";
import getSwitchItems from "./switch";
import getWifiItems from "./wifi";

interface DeviceMenuItemsFactory {
    (baseUrl: string, device: Device): DrawerMenuItem[];
}

const menuItemsFactories: Record<DeviceProductType, DeviceMenuItemsFactory> = {
    "openwifi": getOpenWifiItems,
    "openlan": getOpenLanItems,
    "wifi": getWifiItems,
    "metrolinq": getMetrolinqItems,
    "meshlinq": getMeshlinqItems,
    "switch": getSwitchItems,
    "glinq": getGlinqItems
};

export function getMenuItems(context: PartialContext) {
    if (!context.device || !context.site) {
        return [];
    }

    const site = new Site(context.site);
    const device = new Device(context.device);
    const user = new User(context);
    const includeConfig = user.hasPermission("view_config") && site.configEnabled && device.configEnabled;
    const itemsFactory = menuItemsFactories[device.productType];

    if (itemsFactory === undefined) {
        throw new Error(`No device menu factory for product type ${device.productType}`);
    }

    const baseUrl = "/site/:siteId/device/:deviceId";

    const items = itemsFactory(baseUrl, device);

    if (includeConfig) {
        items.push({
            icon: "build",
            text: _("Configuration"),
            href: `${baseUrl}/config`
        });
    }

    return items;
}

export default function Index() {
    const menuItems = getMenuItems(useContext(pageContext));

    return <DrawerMenu items={menuItems} />;
}
