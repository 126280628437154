import React from "react";

import _ from "app/lang";
import { showSystemDialog } from "app/page/Page";
import ConfirmationDialog from "shared/dialogs/ConfirmationDialog";
import InputDialog from "shared/dialogs/InputDialog";

interface InputOptions {
    title?: string;
    placeholder?: string;
    initialValue?: string;
    submitLabel?: string;
    cancelLabel?: string;
    allowBlank?: boolean;
    validate?: (promptValue: string) => boolean | string | Promise<boolean | string>;
}

interface ConfirmationOptions {
    title?: string;
    submitLabel?: string;
    cancelLabel?: string;
    dangerous?: boolean;
}

function convertNewlinesToBr(content: string): React.ReactNode {
    const lines = content.split(/\r?\n/);

    if (lines.length === 1) {
        return content;
    }

    const result: React.ReactNodeArray = [];

    lines.forEach((line, index) => {
        result.push(line);
        result.push(<br key={index} />);
    });

    return result;
}

export function askConfirmation(content: React.ReactNode, options: ConfirmationOptions = {}) {
    const title = options.title || _("Confirmation Required");
    const submitLabel = options.submitLabel || _("OK");
    const cancelLabel = options.cancelLabel || _("Cancel");

    if (typeof content === "string") {
        content = convertNewlinesToBr(content);
    }

    return new Promise((resolve) => {
        const systemDialog = (
            <ConfirmationDialog
                title={title}
                submitLabel={submitLabel}
                cancelLabel={cancelLabel}
                onClose={resolve}
                dangerous={options.dangerous}
            >
                {content}
            </ConfirmationDialog>
        );

        showSystemDialog(systemDialog);
    });
}

export function requestInput(prompt: React.ReactNode, options?: InputOptions): Promise<string> {
    options = options ?? {};

    const title = options.title || _("Input Required");
    const submitLabel = options.submitLabel || _("OK");
    const cancelLabel = options.cancelLabel || _("Cancel");
    const { validate, initialValue, placeholder, allowBlank } = options;

    if (typeof prompt === "string") {
        prompt = convertNewlinesToBr(prompt);
    }

    return new Promise((resolve) => {
        const systemDialog = (
            <InputDialog
                title={title}
                submitLabel={submitLabel}
                cancelLabel={cancelLabel}
                validate={validate || (() => true)}
                initialValue={initialValue}
                placeholder={placeholder}
                onSubmit={resolve}
                allowBlank={!!allowBlank}
            >
                {prompt}
            </InputDialog>
        );

        showSystemDialog(systemDialog);
    });
}