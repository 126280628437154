import React from "react";

// eslint-disable-next-line no-restricted-imports
import { createStyles, Link as MuiLink, makeStyles, Theme } from "@material-ui/core";

import { browserHistory, getUrlParams, staticContext } from "./ContextProvider";

export const FE2_PAGE_PREFIX = "/v2";

const styles = (theme: Theme) => createStyles({
    button: {
        color: "inherit",
        "&:hover": {
            textDecoration: "none"
        }
    },
    link: {},
});

const useStyles = makeStyles(styles);

interface Props {
    to: string;
    children: React.ReactNode;
    variant?: "button" | "link";
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    trackingId?: string;
    nogo?: string;
}

function isPageReloadRequired(href: string) {
    return window.location.pathname.indexOf(FE2_PAGE_PREFIX) !== 0
        || href.indexOf(FE2_PAGE_PREFIX) !== 0;
}

export function navigate(to: string) {
    const href = insertRouteParams(to);

    if (isPageReloadRequired(href)) {
        // Switch between FE2 and legacy page
        window.location.href = href;
    } else {
        // Tells react router to navigate.
        // We're using this method because
        // PageLinks can be inserted into
        // Trans elements as strings.
        browserHistory.push(href);
    }
}

export function insertRouteParams(route: string) {
    const urlParams = getUrlParams();

    route = route.replace(":cloudId", String(urlParams.cloudId));
    route = route.replace(":userId", String(staticContext.user.id));

    if (urlParams.siteId) {
        route = route.replace(":siteId", String(urlParams.siteId));
    }

    if (urlParams.deviceId) {
        route = route.replace(":deviceId", String(urlParams.deviceId));
    }

    return route;
}

export default function Link(props: Props) {
    const classes = useStyles();
    const href = insertRouteParams(props.to);
    const { variant = "button" } = props;
    const className = props.className || classes[variant];

    if (href.startsWith("http")) {
        console.warn("Violation in " + href);
        throw new Error("Do not use page/Link for external pages. Use ExternalLink.");
    }

    function handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        if (props.onClick) {
            props.onClick(event);
        }

        if (props.nogo === undefined) {
            navigate(href);
        }
    }

    return (
        <MuiLink
            className={className}
            href={href}
            onClick={handleClick}
            data-tracking-id={props.trackingId}
        >
            {props.children}
        </MuiLink>
    );
}
