import { TaskType } from "shared/types";

export type TaskState =
    | "TASK_PENDING"
    | "TASK_WAIT_ACK"
    | "TASK_CANCELED"
    | "TASK_RUNNING"
    | "TASK_SUCCESS"
    | "TASK_FAIL"
    | "TASK_TIMEOUT"
    | "TASK_STANDBY";

export interface TaskAttributes {
    id: string;
    type_id: TaskType;
    task_state: TaskState;
    device_id: string | null;
    device_name: string | null;
    site_id: string;
    site_name: string;
    execute_at: string;
    completed_at: string;
    tasks_viewed_at: string | null;
    task_params: any;
    stateCounts?: Record<TaskState, string>;
}

export class Task {
    public readonly id: string;
    public readonly type: TaskType;
    public readonly state: TaskState;
    public readonly params: any;
    public readonly executeAt: Date | null;
    public readonly completedAt: Date | null;
    public readonly deviceId?: string;
    public readonly deviceName?: string;
    public readonly siteId: string;
    public readonly siteName: string;
    public readonly stateCounts?: Record<TaskState, number>;
    private _isSeen: boolean;

    constructor(attrs: TaskAttributes) {
        this.id = attrs.id;
        this.type = attrs.type_id;
        this.state = attrs.task_state;
        this.params = attrs.task_params || {};
        this.executeAt = attrs.execute_at ? new Date(attrs.execute_at) : null;
        this.completedAt = attrs.completed_at ? new Date(attrs.completed_at) : null;

        if (attrs.device_id !== null) {
            this.deviceId = attrs.device_id;
        }

        if (attrs.device_name !== null) {
            this.deviceName = attrs.device_name;
        }

        this.siteId = attrs.site_id;
        this.siteName = attrs.site_name;

        this.stateCounts = {
            TASK_CANCELED: 0,
            TASK_FAIL: 0,
            TASK_PENDING: 0,
            TASK_RUNNING: 0,
            TASK_STANDBY: 0,
            TASK_SUCCESS: 0,
            TASK_TIMEOUT: 0,
            TASK_WAIT_ACK: 0,
        };

        if (attrs.stateCounts !== undefined) {
            for (const key of Object.keys(attrs.stateCounts)) {
                const state = key as TaskState;
                const count = parseInt(attrs.stateCounts[state], 10) || 0;

                this.stateCounts[state] = count;
            }
        }

        if (attrs.tasks_viewed_at) {
            const tasksViewedAt = new Date(attrs.tasks_viewed_at);

            if (this.completedAt !== null && this.completedAt > tasksViewedAt) {
                this._isSeen = false;
            } else if (this.state !== "TASK_SUCCESS"
                && this.executeAt !== null
                && this.executeAt > tasksViewedAt) {
                this._isSeen = false;
            } else {
                this._isSeen = true;
            }
        } else {
            this._isSeen = false;
        }
    }

    isFinished() {
        return isTaskFinished(this.state);
    }

    getDeviceCount() {
        if (this.stateCounts === undefined) {
            return this.deviceId !== undefined ? 1 : 0;
        }

        let deviceCount = 0;

        for (const count of Object.values(this.stateCounts)) {
            deviceCount += count;
        }

        return deviceCount;
    }

    getErrorCount() {
        if (this.stateCounts === undefined) {
            return 0;
        }

        return this.stateCounts.TASK_CANCELED
            + this.stateCounts.TASK_TIMEOUT
            + this.stateCounts.TASK_FAIL;
    }

    getPercentComplete(): number | undefined {
        if (this.stateCounts === undefined) {
            return undefined;
        }

        // Still not 100% sure about this
        if (this.isFinished()) {
            return 100;
        }

        let finishedCount = 0;
        let totalCount = 0;

        for (const state of Object.keys(this.stateCounts) as TaskState[]) {
            const count = this.stateCounts[state];

            if (isTaskFinished(state)) {
                finishedCount += count;
            }

            totalCount += count;
        }

        if (totalCount > 0) {
            return Math.round(100 * finishedCount / totalCount);
        } else {
            return undefined;
        }
    }

    get isSeen() {
        return this._isSeen;
    }

    markAsSeen() {
        this._isSeen = true;
    }
}

function isTaskFinished(state: TaskState) {
    const finishedStatus: TaskState[] = [
        "TASK_FAIL",
        "TASK_TIMEOUT",
        "TASK_CANCELED",
        "TASK_SUCCESS"
    ];

    return finishedStatus.includes(state);
}
