import React, { useEffect, useRef, useState } from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";

import Icon from "app/Icon";
import request from "app/http";
import _ from "app/lang";
import Link from "app/page/Link";

import { ActionProps } from "../Action";
import ActionButton from "../ActionButton";
import ActionPopover from "../ActionPopover";

import CloudList from "./CloudList";
import InvitationList from "./InvitationList";
import { Cloud, compareClouds, createCloud, createInvitation, Invitation } from "./models";
import { CloudsAPIResponse } from "./types";

interface State {
    clouds?: Cloud[];
    invitations?: Invitation[];
    openedSubMenu?: string;
    unreadInvitationCount?: number;
}

interface Props extends ActionProps {}

export default function CloudAction(props: Props) {
    const {
        name,
        open,
        tooltip,
        iconId,
        dropdownText,
        dropdownIcon,
        dropdown,
        onClick,
        onClose,
        fullScreen,
        showMenuButton
    } = props;

    const [state, setState] = useState<State>({});
    const { clouds = [], invitations = [], openedSubMenu, unreadInvitationCount = 0 } = state;

    useEffect(() => {
        if (open) {
            request("/apiv2/clouds").then((response: CloudsAPIResponse) => {
                setState({
                    ...state,
                    invitations: response.invitations.map(createInvitation),
                    clouds: response.clouds.map(createCloud).sort(compareClouds),
                    openedSubMenu: response.invitations.length > 0 ? "invitations" : "clouds"
                });
            });
        }
    }, [open]);

    useEffect(() => {
        if (open && unreadInvitationCount === 0 && openedSubMenu === "invitations") {
            setState({
                ...state,
                openedSubMenu: "clouds"
            });
        }
    }, [unreadInvitationCount]);

    useEffect(() => {
        request("/apiv2/user/unread-invitations").then((res: {unreadInvitationCount: number}) => {
            setState({
                ...state,
                unreadInvitationCount: res.unreadInvitationCount
            });
        });
    }, []);

    const decrementUnreadInvitationCount = () => unreadInvitationCount < 1 ? 0 : unreadInvitationCount - 1;

    const removeInvitation = (invitation: Invitation) => {
        return invitations.filter((_invitation: Invitation) => {
            return _invitation.cloud.id !== invitation.cloud.id;
        });
    };

    const handleAccept = (invitation: Invitation) => {
        return request({
            url: "/apiv2/user/membership/join",
            method: "POST",
            data: {
                cloudId: invitation.cloud.id
            }
        }).then(() => {
            clouds.push(invitation.cloud);
            clouds.sort(compareClouds);

            setState({
                ...state,
                invitations: removeInvitation(invitation),
                clouds,
                unreadInvitationCount: decrementUnreadInvitationCount()
            });
        });
    };

    const handleReject = (invitation: Invitation) => {
        return request({
            url: "/apiv2/user/membership/decline",
            method: "POST",
            data: {
                cloudId: invitation.cloud.id
            }
        }).then(() => {
            setState({
                ...state,
                invitations: removeInvitation(invitation),
                clouds,
                unreadInvitationCount: decrementUnreadInvitationCount()
            });
        });
    };

    const buttonElement = useRef<HTMLButtonElement>(null);

    const button = showMenuButton ? (
        <ActionButton id="panel-active-cloud" // ID is used in integration tests
            onClick={() => onClick(name)}
            tooltip={tooltip}
            iconId={iconId}
            dropdownText={dropdownText}
            dropdownIcon={dropdownIcon}
            active={open}
            dropdown={dropdown}
            badgeColor="secondary"
            badgeContent={unreadInvitationCount}
            buttonRef={buttonElement}
            trackingId={props.trackingId}
        />
    ) : null;

    const onSubMenuClick = (submenu: string) => {
        return (opened: boolean) => {
            setState({
                ...state,
                openedSubMenu: opened ? submenu : undefined
            });
        };
    };

    const handleClose = () => onClose(name);

    return (
        <>
            {button}

            <ActionPopover open={open}
                minWidth={unreadInvitationCount ? 240 : 0}
                anchorEl={buttonElement.current}
                onClose={handleClose}
                title={tooltip}
                fullScreen={fullScreen}
                showTitleOnlyWhenFullScreen={true}
            >
                <InvitationList invitations={invitations}
                    unreadCount={unreadInvitationCount ? unreadInvitationCount : 0}
                    onAccept={handleAccept}
                    onReject={handleReject}
                    opened={openedSubMenu === "invitations"}
                    onMenuClick={onSubMenuClick("invitations")}
                    trackingId={`${props.trackingId}-invitations`}
                />
                <CloudList clouds={clouds}
                    opened={openedSubMenu === "clouds"}
                    onMenuClick={onSubMenuClick("clouds")}
                    trackingId={`${props.trackingId}-clouds`}
                />

                <Link to="/v2/cloud/:cloudId/clouds" trackingId={`${props.trackingId}-manageClouds`}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Icon small id="settings" />
                        </ListItemIcon>
                        <ListItemText primary={_("Manage Clouds")} />
                    </MenuItem>
                </Link>
                <Link to="/v2/cloud/:cloudId/edit" trackingId={`${props.trackingId}-cloudProperties`}>
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <Icon small id="edit_attributes" />
                        </ListItemIcon>
                        <ListItemText primary={_("Cloud Properties")} />
                    </MenuItem>
                </Link>
            </ActionPopover>

        </>
    );
}
