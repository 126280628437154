import merge from "deepmerge";

import createBreakpoints, { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme, { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { PaletteOptions, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

import { PageLevel } from "app/page/ContextProvider";
import { MessageVariant } from "app/private/SystemMessage";

export const fontFamily = "Open Sans";
export const fontSize = 13;
export const fontWeights = [300, 400, 600, 700];

export const appBarHeight = 54;
export const drawerWidth = 192;
export const drawerMenuHeaderHeight = 42;

export const spacingUnit = 8;
export const defaultContentMarginMultiplier = 3;
export const listContentBottomMarginMultiplier = 2;

/** Input field dimension
 *  @see https://app.zeplin.io/project/56c321dd97dd602237377361/screen/5a44a52379da9a9c123a36e9 */
export const maxInputWidth = 256;

export const defaultTextColor = "#3c3c3c";
export const lighterTextColor = "#6a6a6a";

export const defaultLinkColor = "#579dc4";
export const defaultIconColor = "rgba(0, 0, 0, .54)";

export const colorDarkGreen = "#369b30";
export const colorGreen = "#7cd06f";
export const colorRed = "#dd5a43";
export const colorOrange = "#ff9e16";
export const colorOrangeWarning = "#F3BF22";
export const colorYellow = "#f5d33c";
export const colorBlue = "#1982e1";
export const colorGrey = "#979797";
export const colorLightGrey = "#f7f7f7";

export const colorCloud = "#3ba1ae";
export const colorCloudLight = "#e1f3f4";
export const colorCloudHover = "#72bec5";
export const colorSite = "#9ebf52";
export const colorSiteLight = "#f0f2de";
export const colorSiteHover = "#c7d36f";
export const colorDevice = "#32639e";
export const colorDeviceLight = "#dfe8f4";
export const colorDeviceHover = "#5584ac";

export const darkBgColor = colorLightGrey;
export const borderDividerColor = "#f2f2f0";

export interface ThemeColorOptions extends SimplePaletteColorOptions {
    bottom?: string;
    border?: string;
    darkBorder?: string;
}

export interface VpcOptions {
    theme?: string;
    logo?: string;
}

interface ThemeColors {
    level: {
        cloud: ThemeColorOptions;
        site: ThemeColorOptions;
        device: ThemeColorOptions;
    };

    danger: ThemeColorOptions;

    link: string;

    button: {
        outlined: ThemeColorOptions;
        text: ThemeColorOptions;
    };

    appBar: {
        background: string;
        breadcrumbs: string;
    };

    drawer: {
        background: string;
        itemHoverBackground: string;
        selectedItemBackground: string;
        main: string;
        borderBottom: string;
    };

    status: {
        online: string;
        offline: string;
        blocked: string;
    };
}

type VpcThemeColors = Partial<ThemeColors>;

type ThemeColorsWithPrimary = ThemeColors & { primary: ThemeColorOptions };

type MessageStyles = Record<MessageVariant, CSSProperties>;

declare module "@material-ui/core/styles/createMuiTheme" {
    // eslint-disable-next-line no-shadow
    interface ThemeOptions {
        colors: Partial<ThemeColorsWithPrimary>;
        messageStyles?: MessageStyles;
        logoImage?: string;
    }

    interface Theme {
        colors: ThemeColorsWithPrimary;
        messageStyles: MessageStyles;
        logoImage?: string;
    }
}

export const breakpoints: Record<Breakpoint, number> = {
    xs: 0,
    sm: 600,
    md: 980,
    lg: 1280,
    xl: 1920
};

const defaultColors: Readonly<ThemeColors> = {
    level: {
        cloud: {
            main: colorCloud,
            dark: colorCloudHover,
            light: colorCloudLight,
            contrastText: "#fff",
            bottom: colorCloud
        },

        site: {
            main: colorSite,
            dark: colorSiteHover,
            light: colorSiteLight,
            contrastText: "#fff",
            bottom: colorSite
        },

        device: {
            main: colorDevice,
            dark: colorDeviceHover,
            light: colorDeviceLight,
            contrastText: "#fff",
            bottom: colorDevice
        }
    },

    button: {
        outlined: {
            main: "#fff",
            dark: "#f3f3f3",
            bottom: "#fcfcfc",
            contrastText: defaultTextColor,
            border: "#b9b9b9",
            darkBorder: "#afafaf"
        },

        text: {
            main: "transparent",
            dark: "#f3f3f3",
            contrastText: "inherit"
        }
    },

    link: defaultLinkColor,

    danger: {
        main: "#d15b47",
        dark: "#b8422e",
        bottom: "#c44e3a",
        contrastText: "#fff"
    },

    status: {
        online: "#69aa46",
        offline: "#e53b3b",
        blocked: "#e53b3b"
    },

    appBar: {
        background: "#393939",
        breadcrumbs: colorCloud,
    },

    drawer: {
        background: "#fbfbfb",
        itemHoverBackground: "rgba(0, 0, 0, 0.035)",
        selectedItemBackground: "rgba(0, 0, 0, 0.035)",
        main: lighterTextColor,
        borderBottom: "1px solid #d9d9d9"
    }
};

const VpcTheme1Colors: Readonly<VpcThemeColors> = {
    level: {
        cloud: {
            main: "#8ad1b1",
            dark: "#49b986",
            contrastText: "#fff",
            bottom: "#67c59b",
        },

        site: {
            main: "#8dceec",
            dark: "#40abdc",
            contrastText: "#fff",
            bottom: "#7dc8ea"
        },

        device: {
            main: "#b7c2fa",
            dark: "#6b7dda",
            contrastText: "#fff",
            bottom: "#8c9ae0"
        }
    },

    appBar: {
        background: "#a1a39f",
        breadcrumbs: "#B0EBD1",
    }
};

const VpcTheme2Colors: Readonly<VpcThemeColors> = {
    level: {
        cloud: {
            main: colorCloud,
            dark: colorCloudHover,
            contrastText: "#fff",
            bottom: colorCloud,
        },

        site: {
            main: colorSite,
            dark: colorSiteHover,
            contrastText: "#fff",
            bottom: colorSite
        },

        device: {
            main: colorDevice,
            dark: colorDeviceHover,
            contrastText: "#fff",
            bottom: colorDevice
        }
    },

    appBar: {
        background: "#3b3a36",
        breadcrumbs: "#f18d1a",
    },

    drawer: {
        background: "#3b3a36",
        itemHoverBackground: "rgba(0, 0, 0, 0.035)",
        selectedItemBackground: "rgba(0, 0, 0, 0.035)",
        main: "#dedede",
        borderBottom: "1px solid #737373"
    }
};

const VpcTheme3Colors: Readonly<VpcThemeColors> = {
    level: {
        cloud: {
            main: "#8f67bf",
            dark: "#7e4db9",
            contrastText: "#fff",
            bottom: "#845bb5",
        },

        site: {
            main: "#98c5fc",
            dark: "#5491dc",
            contrastText: "#fff",
            bottom: "#72a6e6"
        },

        device: {
            main: "#d9afa1",
            dark: "#c38875",
            contrastText: "#fff",
            bottom: "#d8a493"
        }
    },

    appBar: {
        background: "#3b3a36",
        breadcrumbs: "#eee8f4"
    },

    drawer: {
        background: "#fbfbfb",
        itemHoverBackground: "#eee8f4",
        selectedItemBackground: "#eee8f4",
        main: lighterTextColor,
        borderBottom: "1px solid #d9d9d9"
    }
};

const defaultPalette: Readonly<PaletteOptions> = {
    background: {
        default: "#fff"
    },
    secondary: {
        main: "#1982e1",
        dark: "#1075d1",
        contrastText: "#fff"
    }
};

const VpcThemePalette: Readonly<PaletteOptions> = {
    background: {
        default: "#fff"
    },
    secondary: {
        main: "#4daaff",
        dark: "#579dc4",
        contrastText: "#fff"
    }
};

const defaultMessageStyles: Readonly<MessageStyles> = {
    success: {
        color: "#5a7052",
        backgroundColor: "rgba(222, 242, 214, 0.95)",
        borderColor: "rgba(90, 112, 82, 0.4)"
    },
    warning: {
        color: "#e28400",
        backgroundColor: "rgba(254, 237, 218, 0.95)",
        borderColor: "rgba(226, 132, 0, 0.4)"
    },
    error: {
        color: "#c8524e",
        backgroundColor: "rgba(255, 222, 221, 0.98)",
        borderColor: "rgba(200, 82, 78, 0.4)",
    }
};

const themeBreakpoints = createBreakpoints({ values: breakpoints });

const defaultTheme: Readonly<ThemeOptions> = {
    typography: {
        fontFamily: `'${fontFamily}', sans-serif`,
        fontSize,
        fontWeightLight: fontWeights[0],
        fontWeightRegular: fontWeights[1],
        fontWeightMedium: fontWeights[2],

        button: {
            fontSize: ".8125rem"
        },

        h5: {
            fontSize: 24,
            lineHeight: 1.6,
        },

        body1: {
            color: defaultTextColor,
            fontSize
        },

        caption: {
            fontSize: 11
        }
    },

    palette: defaultPalette,

    breakpoints: themeBreakpoints,

    spacing: spacingUnit,

    props: {
        MuiTextField: {
            variant: "outlined"
        },
    },

    overrides: {
        MuiInputBase: {
            input: {
                fontSize: fontSize,
                padding: "4px 0",
                lineHeight: "20px"
            }
        },

        MuiLink: {
            root: {
                color: defaultColors.link
            }
        },

        MuiOutlinedInput: {
            input: {
                padding: "6px 6px 6px 14px",
                height: "auto"
            }
        },

        MuiTooltip: {
            tooltip: {
                // fontSize: ".75rem"
            }
        },

        MuiBadge: {
            badge: {
                backgroundColor: "#d15b47",
                color: "white",
                fontWeight: 400,
                fontSize: 10,
                padding: 0
            }
        },

        MuiTableCell: {
            root: {
                padding: 0,
                fontSize,
                "&:first-child": {
                    paddingLeft: 16
                },
                "&:last-child": {
                    paddingRight: 16
                },
                borderBottom: "inherit"
            },

            head: {
                lineHeight: "1rem",
                fontSize,
                fontWeight: fontWeights[2],
                color: defaultTextColor
            }
        },

        MuiTablePagination: {
            toolbar: {
                color: defaultTextColor
            },

            caption: {
                fontSize
            }
        },

        MuiDialogContentText: {
            root: {
                fontSize: fontSize
            }
        },

        MuiDialogActions: {
            root: {
                margin: 0,
                padding: `${spacingUnit * 2}px ${spacingUnit * 3}px`,
                borderTop: "1px solid #d9d9d9",

                "&:first-child": {
                    marginLeft: 0
                },

                "&:last-child": {
                    marginRight: 0
                }
            }
        },

        MuiPaper: {
            elevation1: {
                boxShadow: "1px 1px 3px 0 rgba(213, 213, 213, 0.5), 0.5px 0.5px 1px 0 rgba(236, 236, 236, 0.5)",
            }
        },

        // Temporary: disable focus outline until the bug that allows Divider
        // to receive keyboard focus is fixed:
        // https://github.com/mui-org/material-ui/issues/14892
        MuiDivider: {
            root: {
                outline: "none"
            }
        },

        MuiMenuItem: {
            root: {
                lineHeight: "normal",
                fontSize: fontSize,
                minHeight: 48,
                [themeBreakpoints.up("sm")]: {
                    minHeight: 48,
                },
            }
        },

        MuiListItem: {
            root: {
                paddingTop: spacingUnit * 1.5,
                paddingBottom: spacingUnit * 1.5
            },

            gutters: {
                paddingRight: spacingUnit * 2,
                paddingLeft: spacingUnit * 2
            }
        },

        MuiListItemIcon: {
            root: {
                // Set right margin to zero to avoid the overly wide gap between
                // icon and text – until problem in Material-UI is fixed:
                // https://github.com/mui-org/material-ui/issues/14891
                marginRight: spacingUnit * 1.25,
                color: defaultIconColor,
                /*
                "&:first-child": {
                    marginRight: -6
                },
                */
                minWidth: 0,

                "&:last-of-type": {
                    marginRight: 0,
                    marginLeft: spacingUnit * 2
                }
            }
        },

        MuiListItemText: {
            primary: {
                fontSize,
                color: defaultTextColor
            },

            secondary: {
                fontSize
            }
        },

        MuiListItemSecondaryAction: {
            root: {
                display: "flex",
                color: defaultIconColor
            }
        },

        MuiFormLabel: {
            root: {
                color: defaultTextColor,
                fontSize,
                "&$focused": {
                    color: defaultTextColor
                }
            }
        },

        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 10px) scale(1)"
            }
        },

        MuiFormControlLabel: {
            root: {
                marginLeft: -(spacingUnit + 2)
            }
        },

        MuiCheckbox: {
            root: {
                padding: spacingUnit,
                "& svg": {
                    fontSize: 18
                }
            }
        }
    },

    colors: defaultColors,
    messageStyles: defaultMessageStyles,
    zIndex: {
        appBar: 1030, // modal backdrop of legacy pages is 1040
    }
};

interface VpcThemes {
    [key: string]: Readonly<ThemeOptions>;
}

const vpcThemes: VpcThemes = {
    theme1: {
        palette: VpcThemePalette,
        colors: VpcTheme1Colors
    },
    theme2: {
        palette: VpcThemePalette,
        colors: VpcTheme2Colors
    },
    theme3: {
        palette: VpcThemePalette,
        colors: VpcTheme3Colors
    },
};

function createThemeForColor(primaryColor: ThemeColorOptions): ThemeOptions {
    return {
        colors: {
            primary: primaryColor
        },
        palette: {
            primary: primaryColor
        }
    };
}

export function createTheme(level: PageLevel, customTheme: VpcOptions = {}) {
    let customThemeOptions: Partial<ThemeOptions> = {};

    if (customTheme.theme && vpcThemes[customTheme.theme]) {
        customThemeOptions = vpcThemes[customTheme.theme];
    }

    if (customTheme.logo) {
        customThemeOptions.logoImage = customTheme.logo;
    }

    const theme = merge(defaultTheme, customThemeOptions);

    if (theme.colors.level === undefined) {
        throw new Error("Unexpected error: theme.colors.level is undefined");
    }

    const primaryColor = theme.colors.level[level];

    if (primaryColor === undefined) {
        throw new Error(`Unexpected error: theme.colors.level.${level} is undefined`);
    }

    const themeWithPrimary = merge(theme, createThemeForColor(primaryColor));

    return createMuiTheme(themeWithPrimary);
}

export default createTheme;
